@import "~antd/dist/antd.less";

h1,
h2,
h3,
h4,
h5,
h6,
p,
span,
div,
a,
li,
input,
select,
button {
  font-family: "Mulish", sans-serif;
  margin: 0;
  vertical-align: middle;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: bold;
  color: #0a1629;
}

p,
span,
div,
a,
li {
  font-weight: normal;
}

.ant-table-column-title {
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  @media (max-width: 768px) {
    font-size: 10px;
  }
}

.ant-tag {
  min-width: 84px;
  height: 24px;
  border-radius: 4px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  line-height: 16px;
}

.ant-form-item {
  margin-bottom: 12px;

  .ant-form-item-label {
    padding-bottom: 4px;
    padding-left: 4px;
    label {
      font-size: 18px;
      font-weight: 700;
      @media (max-width: 768px) {
        font-size: 12px;
      }
    }
  }

  .ant-form-item-control {
    .ant-input,
    .ant-select-selector {
      border-radius: 14px;
      border: 1px solid rgba(60, 66, 87, 0.12);
      box-shadow: 0 1px 0 rgba(60, 66, 87, 0.12);
      font-size: 16px;
      height: 38px;

      .ant-select-selection-item,
      .ant-select-selection-placeholder {
        display: flex !important;
        align-items: center;
      }
    }

    textarea {
      height: 92px;
    }

    .ant-input-affix-wrapper {
      border-radius: 14px;
      border: 1px solid rgba(60, 66, 87, 0.12);
      box-shadow: 0 1px 0 rgba(60, 66, 87, 0.12);
      font-size: 16px;
      height: 38px;
      padding: 0 12px;

      .ant-input,
      .ant-select-selector {
        border: none;
        box-shadow: none;
        height: 100%;
        background-color: transparent;
      }
    }
  }
}

.ant-checkbox-inner {
  width: 18px;
  height: 18px;
  border-width: 2px;
  &::after {
    top: 45%;
    left: 14%;
    height: 11.142857px;
  }
}

.ant-select-dropdown {
  border-radius: 10px;
}

.ant-select-arrow {
  width: 22px;
  height: 22px;
  top: 40%;
}

.ant-pagination-disabled .ant-pagination-item-link,
.ant-pagination-disabled:hover .ant-pagination-item-link,
.ant-pagination-disabled:focus-visible .ant-pagination-item-link {
  border: none;
  border-radius: 24px;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ant-pagination-item {
  border-radius: 10px;
  border: none;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.05);
  // display: flex;
  align-items: center;
  justify-content: center;

  a {
    font-weight: 700;
  }
}

.ant-table-thead > tr > th,
.ant-table-tbody > tr > td,
.ant-table tfoot > tr > th,
.ant-table tfoot > tr > td {
  font-size: 14px;
  padding: 12px 16px;
  background-color: #fff;
  @media (max-width: 768px) {
    font-size: 10px;
  }
}

.ant-table-thead > tr > th {
  font-weight: 700;
}

.ant-table-tbody > tr.ant-table-row-level-0 > td {
  font-size: 14px;
  font-weight: 600;
  @media (max-width: 768px) {
    font-size: 10px;
  }
}

.ant-table-tbody > tr.ant-table-row-level-1 > td {
  font-size: 12px;
  padding: 10px 16px;
  @media (max-width: 768px) {
    font-size: 10px;
  }
}

.ant-table-row-indent + .ant-table-row-expand-icon {
  margin-top: 16px;
  margin-right: 8px;
}

.ant-table-selection .ant-checkbox-wrapper,
.ant-table-selection-column .ant-checkbox-wrapper {
  width: 40px !important;
}

.quill {
  .ql-toolbar {
    margin-bottom: 8px;
    &.ql-snow {
      padding: 4px;
      .ql-formats {
        svg {
          width: 15px;
          height: 15px;
        }
      }
    }
  }

  .ql-container {
    min-height: 97px;
    border: 1px solid #d8e0f0 !important;
    border-radius: 14px;
    box-shadow: 0 1px 2px rgba(184, 200, 224, 0.222055);
  }
}

.ant-upload-list-picture .ant-upload-list-item,
.ant-upload-list-picture-card .ant-upload-list-item {
  border-radius: 14px;
}

.ant-upload-list-picture .ant-upload-list-item-info,
.ant-upload-list-picture-card .ant-upload-list-item-info {
  border-radius: 10px;
}

.ant-upload.ant-upload-select-picture-card {
  border-radius: 14px;
}

.ant-picker {
  width: 100%;
  height: 38px;
  border-radius: 12px;
  border: 1px solid rgba(60, 66, 87, 0.12);
}

.ant-tabs-ink-bar {
  max-width: 41px;
}

.ant-tabs-tab-btn {
  font-size: 18px;
  letter-spacing: 0.2px;
  font-weight: bold;
  @media (max-width: 768px) {
    font-size: 12px;
  }
}

.ant-collapse {
  border: none;
  background-color: #ffffff;
}

.ant-collapse-content > .ant-collapse-content-box {
  padding: 0;
}

.ant-collapse > .ant-collapse-item {
  border-bottom: 1px solid #eef0f7;
}

.ant-collapse .ant-table-thead > tr > th,
.ant-collapse .ant-table-tbody > tr > td,
.ant-collapse .ant-table tfoot > tr > th,
.ant-collapse .ant-table tfoot > tr > td {
  padding: 4px 16px;
}

.ant-collapse > .ant-collapse-item > .ant-collapse-header {
  :hover,
  :active,
  :focus {
    background-color: #d8e0ef;
  }
}

.ant-switch {
  min-width: 35px;
  height: 13px;
}
.ant-switch-handle {
  top: -4px;
  left: -1px;
  width: 20px;
  height: 20px;
}
.ant-switch-checked {
  .ant-switch-handle {
    border: 2px solid #3c64b1;
    border-radius: 12px;
  }
}

body {
  width: 100% !important;
  background-color: #f4f9fd;
}

.ant-menu-inline .ant-menu-item,
.ant-menu-inline .ant-menu-submenu-title {
  width: 100%;
}

.ant-table-content {
  overflow-x: auto;
}

.ant-table-thead > tr > th,
.ant-table-tbody > tr > td,
.ant-table tfoot > tr > th,
.ant-table tfoot > tr > td {
  @media (max-width: 768px) {
    padding: 8px;
    .ant-tag,
    a {
      font-size: 10px;
      min-width: 68px;
      height: 20px;
    }
  }
}

.ant-tabs-nav {
  @media (max-width: 768px) {
    background-color: #ffffff;
  }
}

.ant-tabs-top > .ant-tabs-nav .ant-tabs-ink-bar,
.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-ink-bar {
  @media (max-width: 768px) {
    width: 30px !important;
  }
}

.ant-dropdown-menu-title-content {
  svg {
    display: block;
  }
}

.ant-checkbox-wrapper {
  margin-left: 0 !important;
}

.ant-checkbox-group {
  display: flex !important;
  flex-wrap: wrap !important;
  grid-gap: 8px;
}

&.ant-menu-item {
  height: 54px;
  display: flex;
  align-items: center;
  grid-gap: 12px;
  :after {
    border: none;
  }
  .ant-menu-title-content {
    color: #a6acbe;
    font-size: 15px;
    font-weight: bold;
  }
  svg {
    width: 26px;
    height: 26px;
    path {
      fill: #a6acbe;
      &.fill-none {
        fill: transparent !important;
        stroke: #a6acbe;
      }
    }
  }
  &.ant-menu-item-selected {
    .ant-menu-title-content {
      color: #3c64b1;
    }
    svg {
      path {
        fill: #3c64b1;
        &.fill-none {
          fill: transparent !important;
          stroke: #3c64b1;
        }
      }
    }
  }
}

.ant-menu-inline-collapsed {
  .ant-menu-title-content {
    display: none;
  }
}

.no-scroll-bar {
  overflow-y: scroll;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* Internet Explorer 10+ */
}
.no-scroll-bar::-webkit-scrollbar {
  /* WebKit */
  width: 0;
  height: 0;
}

.file-uploader-wrapper label {
  height: 300px;
}

// ant segemented
.ant-segmented-group {
  flex-wrap: wrap;
}

.ant-segmented-item {
  color: #3c64b1;
  cursor: pointer;
  :hover {
    background-color: #3c65b14d;
    color: #000000;
  }
}

.ant-segmented-item-selected {
  background-color: #3c64b1;
  color: #ffffff;

  :hover,
  :active,
  :focus {
    background-color: #3c64b1;
    color: #ffffff;
  }
}

.ant-segmented-thumb {
  background-color: #3c64b1;
}

@primary-color: #3C64B1;@info-color: @primary-color;@success-color: @green-6;@processing-color: @blue-6;@error-color: @red-5;@highlight-color: @red-5;@warning-color: @gold-6;@normal-color: #d9d9d9;@white: #fff;@black: #000;@font-family: "Mulish", sans-serif;@layout-body-background: transparent;